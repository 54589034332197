@import "bootstrap";


@font-face {
	font-family: didot;
	src: url('./assets/fonts/didot.ttf');
}

@font-face {
  font-family: lato-light;
  src: url('./assets/fonts/Lato-Light.ttf')
}

@font-face {
  font-family: lato-thin;
  src: url('./assets/fonts/Lato-Thin.ttf')
}

@font-face {
  font-family: lato-regular;
  src: url('./assets/fonts/Lato-Regular.ttf')
}

@font-face {
  font-family: lato-semibold;
  src: url('./assets/fonts/Lato-Semibold.ttf')
}

h1{
  font-family: 'didot', sans-serif;
}

h2{
  font-family: 'lato-regular', sans-serif;
}

h3{
  font-family: 'didot', sans-serif;
}

h5{
  font-family: 'lato-semibold', sans-serif;
  text-align: left;
}

p {
  font-family: 'lato-regular', sans-serif;
  text-align: left;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.banner{
  background-color: f9f2e6;
  background-image: url('./assets/images/bg_v7.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
}

.about{
  
}

nav.navbar{
  width: 100%;
  top: 0px;
  position: fixed;
  background-color: #E9DDCA;
}

.link-icons{
  display: inline-flex;
  padding-right: 12px;
}

.first-intro{
  margin-left: 100px;
}

.resume-button{
  background-color: #946699 !important;
  align-self: center;
  color: white;
  border: none;
}

.resume-button:hover{
  background-color: #E6CAE9 !important;
  color:black;
}

.contact-button{
  background-color: #946699 !important;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  color: white;
  border: none;
}

.contact-button:hover{
  background-color: #E6CAE9 !important;
  color:black;
}

.footer-img{
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.logo-image{
  padding: 0px;
  margin: 0px;
}

.bubbles{
  
  border-radius: 50px;
  padding: 20px;
}

.left-justify{
  align-items: flex-start !important;
}

.footer-element{
  background-color: #E9DDCA;
  padding: 30px;
  text-align: right;
}

.contact{
  background-image: url('./assets/images/puppy_lettersv2.png');
  background-repeat: no-repeat;
  background-size: 1000px;
}

.tagline{
  text-align: center !important;
}

.typing{
  text-align: center;
}

.sendemail{
  text-align: center;
}

.projects{
  background-color: #f9f2e6;
  margin: 50px;
  padding: 50px;
  border-radius: 50px;
}

.projcard{
  height: 800px;
  margin: 10px;
}